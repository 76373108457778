<template>
  <Header
      is-home="true"
      title="Handcrafted Mattresses"
      lead="Quality mattresses of all shapes and sizes"
      bg-image="home-banner.jpg"/>
  <main>
    <section class="container-xl intro-section">
      <div class="row justify-content-around">
        <div class="col-12 col-sm-5">
          <div class="bg-white shadow-lg">
            <div class="img-container">
              <img src="../assets/img/mattresses-showroom.jpg" class="card-img-top" alt="">
            </div>
            <div class="p-4">
              <h2 class="display-6">Mattresses</h2>
              <p class="">We design and build eleven lines of bedding including the Bed of Roses, Luxury Supreme & Premiere series right here in Thomasville, Georgia.</p>
              <router-link to="browse-mattresses" class="btn btn-outline-primary rounded-pill btn-lg px-4">Browse our
                lineup
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-5">
          <div class="bg-white shadow-lg">
            <div class="img-container">
              <img src="../assets/img/custom-mattress-closeup.jpg" class="card-img-top" alt="">
            </div>
            <div class="p-4">
              <h2 class="display-6">Custom Mattresses</h2>
              <p class="">Do you have an unusual or antique bed frame with special/odd dimensions or style? No problem.
                We can create the perfect custom mattress for your unique needs.</p>
              <router-link to="custom-mattresses" class="btn btn-outline-primary rounded-pill btn-lg px-4">Learn
                more
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="hotel-owners text-white position-relative">
      <div class="container-xl position-relative">
        <div class="row justify-content-lg-around align-items-center">
          <div class="col-12 col-md-5">
            <h2 class="display-6">Hotel Owners</h2>
            <p>We’ve been supplying the hotel/motel industry for 50+ years, so we understand how important it is to get
              you what you need, when you need it. Our beds are made to withstand years of constant use.</p>
            <router-link to="/hotel-mattresses" class="btn btn-outline-light rounded-pill px-4">Learn More</router-link>
          </div>
          <div class="col-12 col-md-5">
            <div class="img-container shadow-lg">
              <img src="../assets/img/hotel-room.jpg" class="shadow-lg" alt="Comfortable bed in hotel room">
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="testimonials">
      <div class="container-xl">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <h2 class="display-6">Testimonials</h2>
            <div class="divider">
              <img src="../assets/img/divider.svg" alt="" role="presentation" class="img-fluid mx-auto">
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="rounded-lg bg-light p-5 mx-3 mb-5">
              <p class="customer-name">Vicki</p>
              <p class="customer-location">Thomasville, GA</p>
              <p class="mb-0">My entire family sleeps on Thomasville Bedding products. Thank you for providing me another great mattress delivered on time. The staff was friendly as always.</p>
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="rounded-lg bg-light p-5 mx-3 mb-5">
              <p class="customer-name">Cynthia</p>
              <p class="customer-location">Oak Grove, LA</p>
              <p class="mb-0">My husband and I recently slept on your bed in Gatlinburg TN. The hotel owner informed us that it was your Bed of Roses. We had an excellent night's sleep and would like more information on purchasing a set.</p>
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="rounded-lg bg-light p-5 mx-3 mb-5">
              <p class="customer-name">Alex</p>
              <p class="customer-location">Thomasville, GA</p>
              <p class="mb-0">I wanted to let you know I was thoroughly impressed with your prompt delivery. I bought
                my beds on Thursday, and they were set up and delivered on Friday. Thank you for accommodating my
                schedule!</p>
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="rounded-lg bg-light p-5 mx-3 mb-5">
              <p class="customer-name">Cole</p>
              <p class="customer-location">Valdosta, GA</p>
              <p class="mb-0">I just wanted to say that it was a breath of fresh air doing business with you. We were
                treated very nice, got a fair price, and even a tour of the factory. Glad there are still a few things
                made in the good ol USA!</p>
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="rounded-lg bg-light p-5 mx-3 mb-5">
              <p class="customer-name">Amanda</p>
              <p class="customer-location">Thomasville, GA</p>
              <p class="mb-0">My husband and I recently bought your Premier Mattress set. When we went to rotate it
                we noticed it weighed twice as much as our old bed, and you can sleep on both sides!!! We have been
                sleeping very good and feel like we got a lot of mattress for our money.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Header from '@/components/Header'

export default {
  name: 'Home',
  components: { Header },
  mounted () {
    document.title = 'Thomasville Bedding'
  }
}
</script>

<style scoped lang="scss">
.intro-section {
  margin-top: 100px;
  margin-bottom: 100px;
}

.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.hotel-owners {
  background: $blue;
  border-top: 5px solid $red;
  margin-top: 200px;
  margin-bottom: 180px;

  h2 {
    color: white;
  }

  .img-container {
    margin-top: -45px;
    margin-bottom: 30px;
    position: relative;

    img {
      width: 100%;
      position: relative;
      z-index: 2;
    }
  }

  .background-graphic {
    background: #ffffff;
    height: 337px;
    width: 555px;
    position: absolute;
    top: -33px;
    left: 50%;
    border-radius: 273px 0 273px 0;
    transform: rotate(45deg);
    opacity: .1;
  }
}

.testimonials {
  margin-top: 120px;
  margin-bottom: 120px;


  .divider {
    margin-bottom: 75px;

    > img {
      max-width: 25em;
    }
  }

  .customer-name {
    margin-bottom: 0;
    font: bold 1.25em Avenir, sans-serif;
  }

  .customer-location {
    color: $red;
  }
}
.rounded-lg.bg-light {
  min-height: 345px;
}
</style>
