<template>
  <footer class="text-light p-5">
    <div class="container-xl">
      <div class="row">
        <div class="col-12 col-md-4 text-center text-lg-start">
          <div class="">
            <h3>Thomasville<br> Bedding Company</h3>
            <div class="contact-methods">
              <div class="mb-2">
                <a href="tel:12292268650"><i class="fa-light fa-phone"></i>1-229-226-8650</a></div>
              <div>
                <a href="mailto:thomasvillebedding@gmail.com" class=""><i
                    class="fa-light fa-envelope"></i>thomasvillebedding@gmail.com</a>
              </div>
            </div>
            <p>Or visit us at: <br>
              14606 US Highway 19 South <br>
              Thomasville, Georgia 31757</p>
          </div>
        </div>
        <div class="col-12 col-md-4 text-center mt-4 mb-5 my-lg-0">
          <img src="../assets/img/made-in-usa-red.svg"
               alt="Our products are proudly made in the USA"
               class="d-inline-block justify-content-center">
        </div>
        <div class="col-12 col-md-4 text-center text-lg-start">
          <div class="mx-auto">
            <h3 class="ms-lg-3">Links</h3>
            <div class="d-lg-flex">
              <ul class="list-unstyled me-xl-5">
                <li>
                  <router-link class="footer-link" to="browse-mattresses">Browse Mattresses</router-link>
                </li>
                <li>
                  <router-link class="footer-link" to="custom-mattresses">Custom Mattresses</router-link>
                </li>
                <li>
                  <router-link class="footer-link" to="hotel-mattresses">Hotel Mattresses</router-link>
                </li>
                <li>
                  <router-link class="footer-link" to="bedding-accessories">Bedding Accessories</router-link>
                </li>
              </ul>
              <ul class="list-unstyled">
                <li>
                  <router-link class="footer-link" to="about">About</router-link>
                </li>
                <li>
                  <router-link class="footer-link" to="contact">Contact</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3">
          <a class="fbm-link" href="//forbenmedia.com"><i class="fa-light fa-pen-ruler"></i> Design by Forbenmedia</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped lang="scss">
footer {
  background: $blue;
  color: white;
  line-height: 2;
  margin-top: 100px;

  h3 {
    color: white
  }

  a,
  .footer-link,
  .contact-methods a {
    border-radius: .3em;
    color: white;
    margin-bottom: 1em;
    padding: .4em 1em;
    text-decoration: none;

    &:hover,
    &:focus {
      background: $red;
      text-decoration: none;
      color: white;
    }
  }

  .contact-methods a {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.8);
    margin-bottom: 0;
    padding: .2em .7em;
    white-space: nowrap;

    &:hover,
    &:focus {
      background: $red;
      color: white;
      border-color: transparent;
    }
  }

  img {
    max-width: 80px;
  }

  li {
    line-height: 2.65;
  }

  .fbm-link {
    border: 1px solid #375072;
    border-radius: .3em;
    color: #375072;
    margin-bottom: 1em;
    padding: .6em 1em;

    &:hover,
    &:focus {
      background: #568ad1;
      border-color: transparent;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  a > svg {
    margin-top: -0.1em;
    margin-right: 0.3em;
  }
}
</style>
