<template>
  <header class="banner position-relative" :style="setBackgroundImage">
    <div class="d-flex justify-content-center align-items-center mt-5 h-100">
      <div class="banner-container text-center  px-5" :class="lead ? 'py-3' : 'py-5'">
        <h1 class="display-2">{{ title }}</h1>
        <p v-if="lead" class="lead">{{ lead }}</p>
      </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" class="section-divider" viewBox="0 0 500 30">
      <path d="M0,0L250,22.23L500,0L500,29.24L0,29.24L0,0Z"/>
    </svg>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: ['title', 'lead', 'isHome', 'bgImage'],
  computed: {
    setBackgroundImage () {
      return `background-image: url(./assets/${this.bgImage})`
    }
  },
}
</script>

<style scoped lang="scss">
.banner {
  background-size: cover;
  height: 650px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 105px;
  margin-bottom: 100px;
  position: relative;

}

.banner-container {
  background: white;
  color: $blue !important;
  position: absolute;
  bottom: 0;
  z-index: 1;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
</style>
