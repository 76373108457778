import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/browse-mattresses',
    name: 'BrowseMattresses',
    component: () => import(/* webpackChunkName: "browseMattresses" */ '../views/BrowseMattresses.vue')
  },
  {
    path: '/custom-mattresses',
    name: 'CustomMattresses',
    component: () => import(/* webpackChunkName: "customMattresses" */ '../views/CustomMattresses.vue')
  },
  {
    path: '/hotel-mattresses',
    name: 'HotelMattresses',
    component: () => import(/* webpackChunkName: "hotelMattresses" */ '../views/HotelMattresses.vue')
  },
  {
    path: '/bedding-accessories',
    name: 'BeddingAccessories',
    component: () => import(/* webpackChunkName: "hotelMattresses" */ '../views/BeddingAccessories.vue')
  },
  {
    path: '/models/:url',
    name: 'ModelDetail',
    component: () => import(/* webpackChunkName: "ModelDetail" */ '../views/ModelDetail.vue')
  },

  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHistory('/clients/tb22/'),
  routes
})

router.beforeEach(() => {
  window.scrollTo(0, 0);
})

export default router
