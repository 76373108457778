<template>
  <Menu/>
  <router-view/>
  <Footer/>
</template>
<script>
import Menu from '@/components/Menu'
import Footer from '@/components/Footer'
export default {
  name: 'App',
  components: {
    Menu,
    Footer
  }
}
</script>
<style lang="scss">
body {
  color: inherit;
}
</style>
